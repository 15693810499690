/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.monotype.com/
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family:"BritishCouncilSansW01-Headline";
    src:url("fonts/BritishCouncilSansW01-Headline.woff") format("woff");
}

@font-face {
    font-family:"BritishCouncilSansW05-Headline";
    src:url("fonts/BritishCouncilSansW05-Headline.woff") format("woff");
}

@font-face {
	font-family:"BritishCouncilSansW07-Headline";
	src: url("fonts/BritishCouncilSansW07-Headline.woff") format("woff");
}

/* Custom css */

.btn-indigo {
	@apply py-2 px-4 bg-indigo text-white font-semibold rounded-lg shadow-md hover:bg-indigo focus:outline-none focus:ring-2 focus:ring-indigo focus:ring-opacity-75;
}

.overlay-topleft {
	top: 0;
	left: 0;
}

.overlay-topright {
	top: 0;
	right: 0;
}

.overlay-bottomleft {
	bottom: 0;
	left: 0;
}

.overlay-bottomright {
	bottom: 0;
	right: 0;
	padding: 10px;
}

.map-overlay {
	position: absolute;
	padding: 10px;
	z-index: 1;
}

p {
	@apply pb-2;
}

h1, h2, h3, h4 {
	@apply text-indigo;
}

.mapboxgl-popup-close-button {
	right: 5px!important;
}